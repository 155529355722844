import { http, HttpResponse } from 'msw'

import { api, isNumeric, queryIntoArrayNumber } from 'app/utils/constants'
import { allRefills } from 'app/mocks/data/transactions/refills'

export const getAllRefills = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.transactions.REFILLS}`,
    ({ request }) => {
        const url = new URL(request.url)
        const limit = url.searchParams.get('limit')
        const offset = url.searchParams.get('offset')

        const managerIdsFilter = url.searchParams.get('machine__site__client')
        const amountMinFilter = url.searchParams.get('total__gte')
        const amountMaxFilter = url.searchParams.get('total__lte')
        const dateStartFilter = url.searchParams.get('date_time__gte')
        const dateEndFilter = url.searchParams.get('date_time__lte')
        const refillFilter = url.searchParams.get('id__icontains')
        const cardNumberFilter = url.searchParams.get('pan__icontains')
        const nameFilter = url.searchParams.get('search')

        let allRefillsFiltered = allRefills

        // Manager filter
        if (!!managerIdsFilter) {
            const managerIdsSplit = queryIntoArrayNumber(managerIdsFilter)

            allRefillsFiltered = allRefillsFiltered.filter((refill) =>
                managerIdsSplit.includes(refill.machine_user.client.id),
            )
        }

        // Date start filter
        if (!!dateStartFilter) {
            allRefillsFiltered = allRefillsFiltered.filter(
                (refill) => refill.date_time.split('T')[0] >= dateStartFilter,
            )
        }

        // Date end filter
        if (!!dateEndFilter) {
            allRefillsFiltered = allRefillsFiltered.filter(
                (refill) => refill.date_time.split('T')[0] <= dateEndFilter,
            )
        }

        // Refill ID filter
        if (!!refillFilter) {
            allRefillsFiltered = allRefillsFiltered.filter((refill) =>
                refill.id.toString().includes(refillFilter),
            )
        }

        // Card number filter
        if (!!cardNumberFilter) {
            allRefillsFiltered = allRefillsFiltered.filter((refill) =>
                refill?.pan.includes(cardNumberFilter),
            )
        }

        // Name filter
        if (!!nameFilter) {
            allRefillsFiltered = allRefillsFiltered.filter(
                (refill) =>
                    refill.machine_user.last_name
                        .toLowerCase()
                        .includes(nameFilter.toLowerCase()) ||
                    refill.machine_user.first_name
                        .toLowerCase()
                        .includes(nameFilter.toLowerCase()),
            )
        }

        // Get the lowest refill
        const lowerRefill =
            allRefillsFiltered.length > 0
                ? allRefillsFiltered.reduce(
                      (min, refill) => Math.min(min, refill.total),
                      Infinity,
                  )
                : 0

        // Get the higher refill
        const higherRefill = allRefillsFiltered.reduce(
            (max, refill) => Math.max(max, refill.total),
            -Infinity,
        )

        // Amount minimum filter
        if (!!amountMinFilter) {
            allRefillsFiltered = allRefillsFiltered.filter(
                (refill) => refill.total >= Number(amountMinFilter),
            )
        }

        // Amount maximum filter
        if (!!amountMaxFilter) {
            allRefillsFiltered = allRefillsFiltered.filter(
                (refill) => refill.total <= Number(amountMaxFilter),
            )
        }

        // Pagination
        const limitArray =
            allRefillsFiltered.length > limit
                ? offset + limit
                : allRefillsFiltered.length

        const results =
            isNumeric(offset) && isNumeric(limit)
                ? allRefillsFiltered.slice(offset, limitArray)
                : allRefillsFiltered

        const next =
            isNumeric(offset) &&
            isNumeric(limit) &&
            offset + results.length < allRefillsFiltered.length
                ? 'again'
                : undefined

        // Total sum refills
        const total = allRefillsFiltered.reduce((sum, refill) => {
            return sum + refill.total
        }, 0)

        return HttpResponse.json({
            count: allRefillsFiltered.length,
            next,
            results,
            min_total: lowerRefill,
            max_total: higherRefill,
            total,
        })
    },
)
