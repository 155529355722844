import { z } from 'zod'
import { Profile } from './profile'

/** User that use the Konsole. */
export const KonsoleUser = z.object({
    id: z.number().positive(),
    email: z.string().email(),
    username: z.string().min(1),
    first_name: z.string().min(1),
    last_name: z.string().min(1).toUpperCase(),
    groups: Profile.array(),
})

/** Form data to create a konsole user. */
export const KonsoleUserCreate = KonsoleUser.omit({
    id: true,
}).extend({
    password: z.string().min(12),
    groups: Profile.shape.id.array(),
})

/** Form data to update a konsole user. */
export const KonsoleUserUpdate = KonsoleUserCreate
