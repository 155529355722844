import { makeStyles } from '@material-ui/core'

import { joinStyles, palette } from 'app/utils/theme'

const useStyles = makeStyles({
    root: {
        '&> tr': {
            borderBottom: `1px solid ${palette.grey[50]}`,
        },
        '&> tr:hover': {
            backgroundColor: `hsla(${palette.primaryRaw.main}, 0.2)`,
        },
        '&> tr > td:first-child': {
            borderLeft: `1px solid ${palette.grey[50]}`,
        },
        '&> tr > td:last-child': {
            borderRight: `1px solid ${palette.grey[50]}`,
        },
    },
})

export const TableBody = ({ className, ...props }) => {
    const classes = useStyles()

    return (
        <tbody
            className={joinStyles(classes.root, className)}
            {...props}
        />
    )
}
