import { makeStyles } from '@material-ui/core'

import { Card } from 'app/ui'
import { joinStyles, palette, various } from 'app/utils/theme'

const useStyles = makeStyles(() => ({
    root: {
        alignItems: 'start',
        width: '100%',
        margin: '30px',
    },

    icon: {
        position: 'absolute',
        transform: `translateY(-50px)`,
        padding: '10px 30px',
        color: 'white',
        fontSize: '2rem',
        backgroundColor: palette.primary.main,
        borderRadius: various.borderRadius,
        boxShadow: various.boxShadow.component,
    },

    text: {
        margin: 'auto',
        marginTop: '30px',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        textAlign: 'center',
    },
}))

export const CardInfo = ({ className, icon, iconStyle, text }) => {
    const classes = useStyles()

    return (
        <Card className={joinStyles(classes.root, className)}>
            <div className={joinStyles(classes.icon, iconStyle)}>{icon}</div>

            <div className={classes.text}>{text}</div>
        </Card>
    )
}
