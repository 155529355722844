import { makeStyles } from '@material-ui/core'

import { joinStyles, palette } from 'app/utils/theme'

const useStyles = makeStyles({
    root: {
        padding: '20px',
        color: palette.secondary.main,
        fontSize: '1.2rem',
        textAlign: 'center',
        backgroundColor: palette.primary.main,
    },
})

export const TableHeaderCell = ({ className, ...props }) => {
    const classes = useStyles()

    return (
        <th
            className={joinStyles(classes.root, className)}
            {...props}
        />
    )
}
