import { z } from 'zod'
import { Category } from 'app/types/products/category'

/** Manager of the Konvives and clients. */
export const Manager = z.object({
    id: z.number().positive(),
    name: z.string().min(1),
    category: Category,
    email: z.string().email(),
    phone: z.string().min(1).max(13),
    // TODO: See later to add the .min(1) for optional values
    email_help: z.string().optional(),
    phone_help: z.string().max(13).optional(),
})

/** Form data to create a manager. */
export const ManagerCreate = Manager.omit({
    id: true,
}).extend({
    category: Category.shape.id,
})

/** Form data to update a manager. */
export const ManagerUpdate = ManagerCreate
