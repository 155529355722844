import * as managers from 'app/mocks/data/users/managers/list'

export const konvive1 = {
    id: 1,
    client: managers.manager8,
    email: 'raven.lucie@konvives.com',
    first_name: 'Lucie',
    last_name: 'RAVEN',
    phone: '0632556684',
}

export const konvive2 = {
    id: 2,
    client: managers.manager3,
    email: 'john.doe@konvives.com',
    first_name: 'John',
    last_name: 'DOE',
    phone: '74852',
}

export const konvive3 = {
    id: 3,
    client: managers.manager5,
    email: 'alice.smith@konvives.com',
    first_name: 'Alice',
    last_name: 'SMITH',
    phone: '98263',
}

export const konvive4 = {
    id: 4,
    client: managers.manager2,
    email: 'bob.rose@konvives.com',
    first_name: 'Bob',
    last_name: 'ROSE',
    phone: '64532',
}

export const konvive5 = {
    id: 5,
    client: managers.manager1,
    email: 'emma.miller@konvives.com',
    first_name: 'Emma',
    last_name: 'MILLER',
    phone: '72109',
}

export const konvive6 = {
    id: 6,
    client: managers.manager7,
    email: 'carlos.lopez@konvives.com',
    first_name: 'Carlos',
    last_name: 'LOPEZ',
    phone: '56387',
}

export const konvive7 = {
    id: 7,
    client: managers.manager6,
    email: 'sarah.white@konvives.com',
    first_name: 'Sarah',
    last_name: 'WHITE',
    phone: '85973',
}

export const konvive8 = {
    id: 8,
    client: managers.manager4,
    email: 'james.martin@konvives.com',
    first_name: 'James',
    last_name: 'MARTIN',
    phone: '93721',
}

export const konvive9 = {
    id: 9,
    client: managers.manager9,
    email: 'michael.davis@konvives.com',
    first_name: 'Michael',
    last_name: 'DAVIS',
    phone: '67482',
}

export const konvive10 = {
    id: 10,
    client: managers.manager10,
    email: 'rachel.lee@konvives.com',
    first_name: 'Rachel',
    last_name: 'LEE',
    phone: '54698',
}

export const konvive11 = {
    id: 11,
    client: managers.manager11,
    email: 'luis.garcia@konvives.com',
    first_name: 'Luis',
    last_name: 'GARCIA',
    phone: '87912',
}

export const konvive12 = {
    id: 12,
    client: managers.manager12,
    email: 'maria.rodriguez@konvives.com',
    first_name: 'Maria',
    last_name: 'RODRIGUEZ',
    phone: '76329',
}

export const konvive13 = {
    id: 13,
    client: managers.manager13,
    email: 'thomas.lopez@konvives.com',
    first_name: 'Thomas',
    last_name: 'LOPEZ',
    phone: '62834',
}

export const konvive14 = {
    id: 14,
    client: managers.manager14,
    email: 'paul.walker@konvives.com',
    first_name: 'Paul',
    last_name: 'WALKER',
    phone: '39847',
}

export const konvive15 = {
    id: 15,
    client: managers.manager15,
    email: 'jessica.young@konvives.com',
    first_name: 'Jessica',
    last_name: 'YOUNG',
    phone: '45298',
}

export const konvive16 = {
    id: 16,
    client: managers.manager16,
    email: 'benjamin.hernandez@konvives.com',
    first_name: 'Benjamin',
    last_name: 'HERNANDEZ',
    phone: '94123',
}

export const konvive17 = {
    id: 17,
    client: managers.manager17,
    email: 'karen.scott@konvives.com',
    first_name: 'Karen',
    last_name: 'SCOTT',
    phone: '87642',
}

export const konvive18 = {
    id: 18,
    client: managers.manager18,
    email: 'robert.king@konvives.com',
    first_name: 'Robert',
    last_name: 'KING',
    phone: '53984',
}

export const konvive19 = {
    id: 19,
    client: managers.manager19,
    email: 'tina.taylor@konvives.com',
    first_name: 'Tina',
    last_name: 'TAYLOR',
    phone: '38495',
}

export const konvive20 = {
    id: 20,
    client: managers.manager20,
    email: 'martin.moore@konvives.com',
    first_name: 'Martin',
    last_name: 'MOORE',
    phone: '64879',
}

export const konvive21 = {
    id: 21,
    client: managers.manager21,
    email: 'julia.thomas@konvives.com',
    first_name: 'Julia',
    last_name: 'THOMAS',
    phone: '72563',
}

export const konvive22 = {
    id: 22,
    client: managers.manager8,
    email: 'william.jackson@konvives.com',
    first_name: 'William',
    last_name: 'JACKSON',
    phone: '49721',
}
