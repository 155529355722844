import * as konvives from 'app/mocks/data/users/konvives/list'

export const refill1 = {
    id: 1,
    date_time: '2024-09-08T17:33:21+02:00',
    machine_user: konvives.konvive1,
    pan: '7856',
    total: 1,
}

export const refill2 = {
    id: 2,
    date_time: '2024-09-18T10:20:00+02:00',
    machine_user: konvives.konvive3,
    pan: '7857',
    total: 2,
}

export const refill3 = {
    id: 3,
    date_time: '2024-09-19T09:15:30+02:00',
    machine_user: konvives.konvive2,
    pan: '7858',
    total: 3,
}

export const refill4 = {
    id: 4,
    date_time: '2024-09-24T11:45:12+02:00',
    machine_user: konvives.konvive4,
    pan: '7859',
    total: 1,
}

export const refill5 = {
    id: 5,
    date_time: '2024-09-28T14:30:00+02:00',
    machine_user: konvives.konvive5,
    pan: '7860',
    total: 4,
}

export const refill6 = {
    id: 6,
    date_time: '2024-10-13T16:20:15+02:00',
    machine_user: konvives.konvive6,
    pan: '7861',
    total: 2,
}

export const refill7 = {
    id: 7,
    date_time: '2024-10-14T09:10:00+02:00',
    machine_user: konvives.konvive7,
    pan: '7862',
    total: 3,
}

export const refill8 = {
    id: 8,
    date_time: '2024-10-14T13:40:25+02:00',
    machine_user: konvives.konvive8,
    pan: '7863',
    total: 1,
}

export const refill9 = {
    id: 9,
    date_time: '2024-10-15T12:05:30+02:00',
    machine_user: konvives.konvive1,
    pan: '7864',
    total: 5,
}

export const refill10 = {
    id: 10,
    date_time: '2024-10-16T08:30:15+02:00',
    machine_user: konvives.konvive2,
    pan: '7865',
    total: 2,
}

export const refill11 = {
    id: 11,
    date_time: '2024-10-18T09:20:45+02:00',
    machine_user: konvives.konvive5,
    pan: '1234',
    total: 15,
}

export const refill12 = {
    id: 12,
    date_time: '2024-10-18T09:25:10+02:00',
    machine_user: konvives.konvive5,
    pan: '5678',
    total: 8,
}

export const refill13 = {
    id: 13,
    date_time: '2024-10-19T10:15:30+02:00',
    machine_user: konvives.konvive2,
    pan: '2345',
    total: 30,
}

export const refill14 = {
    id: 14,
    date_time: '2024-10-19T10:20:15+02:00',
    machine_user: konvives.konvive2,
    pan: '6789',
    total: 5,
}

export const refill15 = {
    id: 15,
    date_time: '2024-10-20T16:20:25+02:00',
    machine_user: konvives.konvive10,
    pan: '3456',
    total: 50,
}

export const refill16 = {
    id: 16,
    date_time: '2024-10-20T16:25:15+02:00',
    machine_user: konvives.konvive10,
    pan: '7890',
    total: 12,
}

export const refill17 = {
    id: 17,
    date_time: '2024-10-21T08:45:30+02:00',
    machine_user: konvives.konvive14,
    pan: '4567',
    total: 3,
}

export const refill18 = {
    id: 18,
    date_time: '2024-10-21T08:50:15+02:00',
    machine_user: konvives.konvive14,
    pan: '8901',
    total: 40,
}

export const refill19 = {
    id: 19,
    date_time: '2024-10-22T10:05:10+02:00',
    machine_user: konvives.konvive9,
    pan: '9012',
    total: 6,
}

export const refill20 = {
    id: 20,
    date_time: '2024-10-22T10:10:00+02:00',
    machine_user: konvives.konvive9,
    pan: '3450',
    total: 25,
}

export const refill21 = {
    id: 21,
    date_time: '2024-10-23T11:15:30+02:00',
    machine_user: konvives.konvive3,
    pan: '8765',
    total: 20,
}

export const refill22 = {
    id: 22,
    date_time: '2024-10-23T11:20:00+02:00',
    machine_user: konvives.konvive15,
    pan: '5432',
    total: 5,
}

export const refill23 = {
    id: 23,
    date_time: '2024-10-24T09:50:15+02:00',
    machine_user: konvives.konvive8,
    pan: '2341',
    total: 60,
}

export const refill24 = {
    id: 24,
    date_time: '2024-10-24T09:52:45+02:00',
    machine_user: konvives.konvive3,
    pan: '6789',
    total: 2,
}

export const refill25 = {
    id: 25,
    date_time: '2024-10-25T14:00:30+02:00',
    machine_user: konvives.konvive12,
    pan: '1209',
    total: 35,
}

export const refill26 = {
    id: 26,
    date_time: '2024-10-25T14:02:00+02:00',
    machine_user: konvives.konvive7,
    pan: '9087',
    total: 12,
}

export const refill27 = {
    id: 27,
    date_time: '2024-10-26T17:25:00+02:00',
    machine_user: konvives.konvive1,
    pan: '5624',
    total: 8,
}

export const refill28 = {
    id: 28,
    date_time: '2024-10-26T17:27:10+02:00',
    machine_user: konvives.konvive21,
    pan: '3945',
    total: 18,
}

export const refill29 = {
    id: 29,
    date_time: '2024-10-27T08:10:00+02:00',
    machine_user: konvives.konvive19,
    pan: '8412',
    total: 45,
}

export const refill30 = {
    id: 30,
    date_time: '2024-10-27T08:15:30+02:00',
    machine_user: konvives.konvive10,
    pan: '6781',
    total: 22,
}
