import { createMicrowave } from './createMicrowave'
import { deleteMicrowave } from './deleteMicrowave'
import { getAllMicrowaves } from './getAllMicrowaves'
import { getMicrowave } from './getMicrowave'
import { updateMicrowave } from './updateMicrowave'

export const microwaves = [
    createMicrowave,
    deleteMicrowave,
    getAllMicrowaves,
    getMicrowave,
    updateMicrowave,
]
