import { z } from 'zod'
import { Manager } from './manager'
import { Permission } from './permission'

/** Profile containing a group of multiple permissions. */
export const Profile = z.object({
    id: z.number().positive(),
    name: z.string().min(1),
    description: z.string().min(1),
    client: Manager,
    permissions: Permission.array(),
})
