import * as sites from 'app/mocks/data/park/sites/standard'

import { ModelRaspberry } from 'app/types/shared/machine'
import { PositionMicrowave } from 'app/types/shared/machine/microwave'

export const microwave1 = {
    id: 1,
    name: 'Microwave 1',
    ip_address: '192.168.0.1',
    site: sites.siteStandard1,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_8GO,
    serial_number: 'M1',
    serial_number_screen: 'S1',
    serial_number_raspberry: 'R1',
    microwave_units: [
        {
            id: 1,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU1',
            brand: 'LG',
            model: 'Cook',
            purchase_invoice: 'https://e-thik.com',
        },
        {
            id: 2,
            position: PositionMicrowave.enum[1],
            is_enabled: false,
            serial_number: 'MU2',
            brand: 'LG',
            model: 'Cook',
            purchase_invoice: 'https://e-thik.com',
        },
        {
            id: 3,
            position: PositionMicrowave.enum[3],
            is_enabled: true,
            serial_number: 'MU3',
            brand: 'LG',
            model: 'Cook2',
            purchase_invoice: 'https://e-thik.com',
        },
    ],
}

export const microwave2 = {
    id: 2,
    name: 'Microwave 2',
    ip_address: '192.168.0.2',
    site: sites.siteStandard1,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_4GO,
    serial_number: 'M2',
    serial_number_screen: 'S2',
    serial_number_raspberry: 'R2',
    microwave_units: [
        {
            id: 4,
            position: PositionMicrowave.enum[3],
            is_enabled: true,
            serial_number: 'MU4',
            brand: 'Samsung',
            model: 'Chief',
            purchase_invoice: 'https://e-thik.com',
        },
        {
            id: 5,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU5',
            brand: 'Cook&Lewis',
            model: 'Brico',
        },
        {
            id: 6,
            position: PositionMicrowave.enum[2],
            is_enabled: false,
            serial_number: 'MU6',
            brand: 'Cook&Lewis',
            model: 'Brico',
        },
    ],
}

export const microwave3 = {
    id: 3,
    name: 'Microwave 3',
    ip_address: '192.168.0.3',
    site: sites.siteStandard1,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_8GO,
    serial_number: 'M3',
    serial_number_screen: 'S3',
    serial_number_raspberry: 'R3',
    microwave_units: [
        {
            id: 7,
            position: PositionMicrowave.enum[1],
            is_enabled: false,
            serial_number: 'MU7',
            brand: 'Samsung',
            model: 'Solo',
        },
        {
            id: 8,
            position: PositionMicrowave.enum[2],
            is_enabled: false,
            serial_number: 'MU8',
            brand: 'Samsung',
            model: 'Multifonction',
        },
        {
            id: 9,
            position: PositionMicrowave.enum[3],
            is_enabled: false,
            serial_number: 'MU9',
            brand: 'Samsung',
            model: 'Solo',
        },
    ],
}

export const microwave4 = {
    id: 4,
    name: 'Microwave 4',
    ip_address: '192.168.0.4',
    site: sites.siteStandard11,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_8GO,
    serial_number: 'M4',
    serial_number_screen: 'S4',
    serial_number_raspberry: 'R4',
    microwave_units: [
        {
            id: 10,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU10',
            brand: 'Panasonic',
            model: 'Master Grill',
        },
        {
            id: 11,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU11',
            brand: 'Samsung',
            model: 'Master Chief',
        },
        {
            id: 12,
            position: PositionMicrowave.enum[3],
            is_enabled: false,
            serial_number: 'MU12',
            brand: 'Panasonic',
            model: 'Master Combo',
        },
    ],
}

export const microwave5 = {
    id: 5,
    name: 'Microwave 5',
    ip_address: '192.168.0.5',
    site: sites.siteStandard12,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_4GO,
    serial_number: 'M5',
    serial_number_screen: 'S5',
    serial_number_raspberry: 'R5',
    microwave_units: [
        {
            id: 13,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU13',
            brand: 'LG',
            model: 'WaveMaster',
        },
        {
            id: 14,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU14',
            brand: 'LG',
            model: 'Solo Inverter',
        },
        {
            id: 15,
            position: PositionMicrowave.enum[3],
            is_enabled: true,
            serial_number: 'MU15',
            brand: 'LG',
            model: 'Solo',
        },
    ],
}

export const microwave6 = {
    id: 6,
    name: 'Microwave 6',
    ip_address: '192.168.0.6',
    site: sites.siteStandard13,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_8GO,
    serial_number: 'M6',
    serial_number_screen: 'S6',
    serial_number_raspberry: 'R6',
    microwave_units: [
        {
            id: 16,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU16',
            brand: 'Sharp',
            model: 'Solo',
        },
        {
            id: 17,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU17',
            brand: 'Samsung',
            model: 'SmartWave',
        },
        {
            id: 18,
            position: PositionMicrowave.enum[3],
            is_enabled: false,
            serial_number: 'MU18',
            brand: 'Bosch',
            model: 'Combiné',
        },
    ],
}

export const microwave7 = {
    id: 7,
    name: 'Microwave 7',
    ip_address: '192.168.0.7',
    site: sites.siteStandard14,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_4GO,
    serial_number: 'M7',
    serial_number_screen: 'S7',
    serial_number_raspberry: 'R7',
    microwave_units: [
        {
            id: 19,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU19',
            brand: 'LG',
            model: 'ProHeat',
        },
    ],
}

export const microwave8 = {
    id: 8,
    name: 'Microwave 8',
    ip_address: '192.168.0.8',
    site: sites.siteStandard15,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_8GO,
    serial_number: 'M8',
    serial_number_screen: 'S8',
    serial_number_raspberry: 'R8',
    microwave_units: [
        {
            id: 20,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU20',
            brand: 'Whirlpool',
            model: 'MaxPro',
        },
        {
            id: 21,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'M21',
            brand: 'LG',
            model: 'ProHeat',
        },
    ],
}

export const microwave9 = {
    id: 9,
    name: 'Microwave 9',
    ip_address: '192.168.0.9',
    site: sites.siteStandard16,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_4GO,
    serial_number: 'M9',
    serial_number_screen: 'S9',
    serial_number_raspberry: 'R9',
    microwave_units: [
        {
            id: 22,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU22',
            brand: 'Bosch',
            model: 'QuickHeat',
            purchase_invoice: 'https://e-thik.com',
        },
        {
            id: 23,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU23',
            brand: 'Bosch',
            model: 'QuickHeat',
        },
    ],
}

export const microwave10 = {
    id: 10,
    name: 'Microwave 10',
    ip_address: '192.168.0.10',
    site: sites.siteStandard17,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_8GO,
    serial_number: 'M10',
    serial_number_screen: 'S10',
    serial_number_raspberry: 'R10',
    microwave_units: [
        {
            id: 24,
            position: PositionMicrowave.enum[3],
            is_enabled: true,
            serial_number: 'MU24',
            brand: 'Whirlpool',
            model: 'HeatWave',
        },
        {
            id: 25,
            position: PositionMicrowave.enum[1],
            is_enabled: false,
            serial_number: 'MU25',
            brand: 'LG',
            model: 'ProHeat',
        },
        {
            id: 26,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU26',
            brand: 'Samsung',
            model: 'Master Chief',
        },
    ],
}

export const microwave11 = {
    id: 11,
    name: 'Microwave 11',
    ip_address: '192.168.0.11',
    site: sites.siteStandard18,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_4GO,
    serial_number: 'M11',
    serial_number_screen: 'S11',
    serial_number_raspberry: 'R11',
    microwave_units: [
        {
            id: 27,
            position: PositionMicrowave.enum[2],
            is_enabled: false,
            serial_number: 'MU27',
            brand: 'LG',
            model: 'ProHeat',
            purchase_invoice: 'https://e-thik.com',
        },
        {
            id: 28,
            position: PositionMicrowave.enum[1],
            is_enabled: false,
            serial_number: 'MU28',
            brand: 'LG',
            model: 'ProHeat',
            purchase_invoice: 'https://e-thik.com',
        },
    ],
}

export const microwave12 = {
    id: 12,
    name: 'Microwave 12',
    ip_address: '192.168.0.12',
    site: sites.siteStandard19,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_8GO,
    serial_number: 'M12',
    serial_number_screen: 'S12',
    serial_number_raspberry: 'R12',
    microwave_units: [
        {
            id: 29,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU29',
            brand: 'Panasonic',
            model: 'InverterX',
        },
        {
            id: 30,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU30',
            brand: 'Bosch',
            model: 'InverterB',
            purchase_invoice: 'https://e-thik.com',
        },
    ],
}

export const microwave13 = {
    id: 13,
    name: 'Microwave 13',
    ip_address: '192.168.0.13',
    site: sites.siteStandard20,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_4GO,
    serial_number: 'M13',
    serial_number_screen: 'S13',
    serial_number_raspberry: 'R13',
    microwave_units: [
        {
            id: 31,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU32',
            brand: 'Samsung',
            model: 'QuickHeat',
        },
        {
            id: 32,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU31',
            brand: 'Samsung',
            model: 'QuickHeat',
        },
    ],
}

export const microwave14 = {
    id: 14,
    name: 'Microwave 14',
    ip_address: '192.168.0.14',
    site: sites.siteStandard21,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_8GO,
    serial_number: 'M14',
    serial_number_screen: 'S14',
    serial_number_raspberry: 'R14',
    microwave_units: [
        {
            id: 33,
            position: PositionMicrowave.enum[3],
            is_enabled: false,
            serial_number: 'MU33',
            brand: 'Brandt',
            model: 'Grill',
        },
        {
            id: 34,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU34',
            brand: 'LG',
            model: 'WavePro',
        },
        {
            id: 35,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU35',
            brand: 'Electrolux',
            model: 'Compact',
        },
    ],
}

export const microwave15 = {
    id: 15,
    name: 'Microwave 15',
    ip_address: '192.168.0.15',
    site: sites.siteStandard1,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_8GO,
    serial_number: 'M15',
    serial_number_screen: 'S15',
    serial_number_raspberry: 'R15',
    microwave_units: [
        {
            id: 36,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU36',
            brand: 'Daewoo',
            model: 'Solo',
            purchase_invoice: 'https://e-thik.com',
        },
        {
            id: 37,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU37',
            brand: 'LG',
            model: 'WavePro',
        },
    ],
}

export const microwave16 = {
    id: 16,
    name: 'Microwave 16',
    ip_address: '192.168.0.16',
    site: sites.siteStandard2,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_4GO,
    serial_number: 'M16',
    serial_number_screen: 'S16',
    serial_number_raspberry: 'R16',
    microwave_units: [
        {
            id: 38,
            position: PositionMicrowave.enum[2],
            is_enabled: false,
            serial_number: 'MU38',
            brand: 'Whirlpool',
            model: 'WaveMaster',
            purchase_invoice: 'https://e-thik.com',
        },
        {
            id: 39,
            position: PositionMicrowave.enum[1],
            is_enabled: false,
            serial_number: 'MU39',
            brand: 'Sharp',
            model: 'ExtraSharp',
            purchase_invoice: 'https://e-thik.com',
        },
    ],
}

export const microwave17 = {
    id: 17,
    name: 'Microwave 17',
    ip_address: '192.168.0.17',
    site: sites.siteStandard3,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_8GO,
    serial_number: 'M17',
    serial_number_screen: 'S17',
    serial_number_raspberry: 'R17',
    microwave_units: [
        {
            id: 40,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU40',
            brand: 'Bosch',
            model: 'QuickHeatX',
        },
        {
            id: 41,
            position: PositionMicrowave.enum[2],
            is_enabled: false,
            serial_number: 'MU41',
            brand: 'Bosch',
            model: 'QuickHeatX',
            purchase_invoice: 'https://e-thik.com',
        },
    ],
}

export const microwave18 = {
    id: 18,
    name: 'Microwave 18',
    ip_address: '192.168.0.18',
    site: sites.siteStandard4,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_8GO,
    serial_number: 'M18',
    serial_number_screen: 'S18',
    serial_number_raspberry: 'R18',
    microwave_units: [
        {
            id: 42,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU42',
            brand: 'LG',
            model: 'ProHeat',
        },
        {
            id: 43,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU43',
            brand: 'Bosch',
            model: 'Grill',
        },
    ],
}

export const microwave19 = {
    id: 19,
    name: 'Microwave 19',
    ip_address: '192.168.0.19',
    site: sites.siteStandard5,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_4GO,
    serial_number: 'M19',
    serial_number_screen: 'S19',
    serial_number_raspberry: 'R19',
    microwave_units: [
        {
            id: 44,
            position: PositionMicrowave.enum[2],
            is_enabled: false,
            serial_number: 'MU44',
            brand: 'Brandt',
            model: 'ExtraHeat',
        },
        {
            id: 45,
            position: PositionMicrowave.enum[1],
            is_enabled: false,
            serial_number: 'MU45',
            brand: 'Electrolux',
            model: 'Encastrable',
        },
        {
            id: 46,
            position: PositionMicrowave.enum[3],
            is_enabled: true,
            serial_number: 'MU46',
            brand: 'LG',
            model: 'ProHeat',
        },
    ],
}

export const microwave20 = {
    id: 20,
    name: 'Microwave 20',
    ip_address: '192.168.0.20',
    site: sites.siteStandard6,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_8GO,
    serial_number: 'M20',
    serial_number_screen: 'S20',
    serial_number_raspberry: 'R20',
    microwave_units: [
        {
            id: 47,
            position: PositionMicrowave.enum[2],
            is_enabled: false,
            serial_number: 'MU47',
            brand: 'Sharp',
            model: 'FastCook',
            purchase_invoice: 'https://e-thik.com',
        },
        {
            id: 48,
            position: PositionMicrowave.enum[1],
            is_enabled: false,
            serial_number: 'MU48',
            brand: 'Samsung',
            model: 'FastCook',
        },
    ],
}

export const microwave21 = {
    id: 21,
    name: 'Microwave 21',
    ip_address: '192.168.0.21',
    site: sites.siteStandard7,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_4GO,
    serial_number: 'M21',
    serial_number_screen: 'S21',
    serial_number_raspberry: 'R21',
    microwave_units: [
        {
            id: 49,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU49',
            brand: 'Panasonic',
            model: 'InverterPro',
            purchase_invoice: 'https://e-thik.com',
        },
        {
            id: 50,
            position: PositionMicrowave.enum[3],
            is_enabled: true,
            serial_number: 'MU50',
            brand: 'Panasonic',
            model: 'InverterPro',
            purchase_invoice: 'https://e-thik.com',
        },
        {
            id: 51,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU51',
            brand: 'Panasonic',
            model: 'InverterPro',
            purchase_invoice: 'https://e-thik.com',
        },
    ],
}

export const microwave22 = {
    id: 22,
    name: 'Microwave 22',
    ip_address: '192.168.0.22',
    site: sites.siteStandard8,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_8GO,
    serial_number: 'M22',
    serial_number_screen: 'S22',
    serial_number_raspberry: 'R22',
    microwave_units: [
        {
            id: 52,
            position: PositionMicrowave.enum[1],
            is_enabled: false,
            serial_number: 'MU52',
            brand: 'LG',
            model: 'Grill',
        },
        {
            id: 53,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU53',
            brand: 'LG',
            model: 'InverterPro',
        },
    ],
}

export const microwave23 = {
    id: 23,
    name: 'Microwave 23',
    ip_address: '192.168.0.23',
    site: sites.siteStandard9,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_8GO,
    serial_number: 'M23',
    serial_number_screen: 'S23',
    serial_number_raspberry: 'R23',
    microwave_units: [
        {
            id: 54,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU54',
            brand: 'LG',
            model: 'HeatMaster',
        },
        {
            id: 55,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU55',
            brand: 'Brandt',
            model: 'HeatMaster',
            purchase_invoice: 'https://e-thik.com',
        },
    ],
}

export const microwave24 = {
    id: 24,
    name: 'Microwave 24',
    ip_address: '192.168.0.24',
    site: sites.siteStandard10,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_4GO,
    serial_number: 'M24',
    serial_number_screen: 'S24',
    serial_number_raspberry: 'R24',
    microwave_units: [
        {
            id: 56,
            position: PositionMicrowave.enum[1],
            is_enabled: false,
            serial_number: 'MU56',
            brand: 'Samsung',
            model: 'QuickWave',
        },
        {
            id: 57,
            position: PositionMicrowave.enum[2],
            is_enabled: false,
            serial_number: 'MU57',
            brand: 'Whirlpool',
            model: 'QuickWave',
        },
    ],
}

export const microwave25 = {
    id: 25,
    name: 'Microwave 25',
    ip_address: '192.168.0.25',
    site: sites.siteStandard11,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_4GO,
    serial_number: 'M25',
    serial_number_screen: 'S25',
    serial_number_raspberry: 'R25',
    microwave_units: [
        {
            id: 58,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU58',
            brand: 'Panasonic',
            model: 'InverterPro',
            purchase_invoice: 'https://e-thik.com',
        },
        {
            id: 59,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU59',
            brand: 'LG',
            model: 'InverterPro',
            purchase_invoice: 'https://e-thik.com',
        },
        {
            id: 60,
            position: PositionMicrowave.enum[3],
            is_enabled: true,
            serial_number: 'MU60',
            brand: 'Samsung',
            model: 'InverterPro',
            purchase_invoice: 'https://e-thik.com',
        },
    ],
}

export const microwave26 = {
    id: 26,
    name: 'Microwave 26',
    ip_address: '192.168.0.26',
    site: sites.siteStandard12,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_8GO,
    serial_number: 'M26',
    serial_number_screen: 'S26',
    serial_number_raspberry: 'R26',
    microwave_units: [
        {
            id: 61,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU61',
            brand: 'Bosch',
            model: 'HeatFast',
            purchase_invoice: 'https://e-thik.com',
        },
        {
            id: 62,
            position: PositionMicrowave.enum[2],
            is_enabled: false,
            serial_number: 'MU62',
            brand: 'Bosch',
            model: 'HeatFast',
        },
    ],
}

export const microwave27 = {
    id: 27,
    name: 'Microwave 27',
    ip_address: '192.168.0.27',
    site: sites.siteStandard13,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_4GO,
    serial_number: 'M27',
    serial_number_screen: 'S27',
    serial_number_raspberry: 'R27',
    microwave_units: [
        {
            id: 63,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU63',
            brand: 'Panasonic',
            model: 'WaveX',
        },
        {
            id: 64,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU64',
            brand: 'Panasonic',
            model: 'WaveX',
        },
    ],
}

export const microwave28 = {
    id: 28,
    name: 'Microwave 28',
    ip_address: '192.168.0.28',
    site: sites.siteStandard14,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_4GO,
    serial_number: 'M28',
    serial_number_screen: 'S28',
    serial_number_raspberry: 'R28',
    microwave_units: [
        {
            id: 64,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU64',
            brand: 'Panasonic',
            model: 'WaveX',
        },
        {
            id: 65,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU65',
            brand: 'Panasonic',
            model: 'WaveX',
        },
        {
            id: 66,
            position: PositionMicrowave.enum[3],
            is_enabled: true,
            serial_number: 'MU66',
            brand: 'Bosch',
            model: 'WaveX',
        },
    ],
}

export const microwave29 = {
    id: 29,
    name: 'Microwave 29',
    ip_address: '192.168.0.29',
    site: sites.siteStandard15,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_8GO,
    serial_number: 'M29',
    serial_number_screen: 'S29',
    serial_number_raspberry: 'R29',
    microwave_units: [
        {
            id: 67,
            position: PositionMicrowave.enum[3],
            is_enabled: false,
            serial_number: 'MU67',
            brand: 'LG',
            model: 'QuickWavePro',
        },
        {
            id: 68,
            position: PositionMicrowave.enum[2],
            is_enabled: true,
            serial_number: 'MU68',
            brand: 'Panasonic',
            model: 'WaveX',
            purchase_invoice: 'https://e-thik.com',
        },
        {
            id: 69,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU69',
            brand: 'Panasonic',
            model: 'WaveX',
        },
    ],
}
