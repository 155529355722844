import { z } from 'zod'

/** Enum permission for the profile. */
export const TypePermission = z.enum(['CREATE', 'DELETE', 'READ', 'UPDATE'])

/** Enum action for the profile permissions. */
export const TypePermissionAction = z.enum(['ADD', 'REMOVE'])

/** Permission to do a specific action in specific data. */
export const Permission = z.object({
    id: z.number().positive(),
    codename: z.string().min(1),
})
