import { z } from 'zod'

/** Category of a product. */
export const Category = z.object({
    /** Category ID. */
    id: z.number().positive(),

    /** Name of the category. */
    name: z.string().min(1),

    /** Priority of the category. */
    priority: z.number().positive(),
})
